<template>
    <div class="promotion-content">
        <div class="keyword-select">
            <el-select v-model="planValue" placeholder="请选择计划名称" style="margin-right: 20px;" @change="selectPlan">
                <el-option
                        v-for="item in planOptions"
                        :key="item.project_name"
                        :label="item.project_name"
                        :value="item.project_name">
                </el-option>
            </el-select>
            <el-select v-model="unitValue" placeholder="请选择单元名称" @change="selectUnit">
                <el-option
                        v-for="item in unitOptions"
                        :key="item.unit_name"
                        :label="item.unit_name"
                        :value="item.unit_name">
                </el-option>
            </el-select>
        </div>
        <el-table :data="crowdList" border style="width: 100%; margin-top: 20px; flex: 1" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '48px', color: '#333333', background: '#F5F5F5'}"
                  :cell-style="{fontSize: '16px',color: '#333333'}">
            <el-table-column prop="type" label="人群名称" width="150" :resizable="false"></el-table-column>
            <el-table-column prop="project_name" label="推广创意" width="150" :resizable="false"></el-table-column>
            <el-table-column prop="unit_name" label="推广单元" width="150" :resizable="false"></el-table-column>
            <el-table-column prop="status" label="流量推广方式" :resizable="false">
            </el-table-column>
            <el-table-column prop="premium" label="花费" width="100" :resizable="false">
            </el-table-column>
            <el-table-column prop="show_num" label="展现量" :resizable="false">
                <template slot-scope="scope">
                    <span>{{scope.row.show_num ? scope.row.show_num : '-'}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="count" label="平均点击花费" :resizable="false">
            </el-table-column>
            <!-- <el-table-column prop="show_rank" label="平均展现排名" width="110" :resizable="false">
                <template slot-scope="scope">
                    <span>{{scope.row.show_rank ? scope.row.show_rank : '-'}}</span>
                </template>
            </el-table-column> -->
            <el-table-column prop="click_num" label="点击量" width="110" :resizable="false">
                <!-- <template slot-scope="scope">
                    <span>{{scope.row.click_num ? scope.row.click_num : '-'}}</span>
                </template> -->
            </el-table-column>
            <el-table-column prop="click_rate" label="点击率（%）" width="140" :resizable="false">
            </el-table-column>
            <el-table-column prop="tran_num" label="成交量" width="110" :resizable="false">

            </el-table-column>
            <el-table-column prop="tran_rate" label="转化率（%）" width="140" :resizable="false"></el-table-column>
        </el-table>
        <el-pagination
                class="operation-pagination"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="listPages.currentPageNum"
                :page-size="listPages.eachPageNum"
                :total="listPages.total"
                :page-sizes="[10]"
                layout=" sizes, prev, pager, next, total, jumper">
        </el-pagination>
    </div>
</template>

<script>
    import _ from "underscore";
    import { mapActions } from "vuex";
    import {keywordOfferRank} from "@/utils/apis";

    export default {
        name: "CreateIdea",
        data() {
            return {
                //是否显示修改输入框
                isShow: null,
                //计划筛选
                planOptions: [],
                planValue: '',
                //单元筛选
                unitOptions: [],
                unitValue: '',
                //人群列表
                crowdList: [
                    {
                        type:"核心人群",
                        project_name:"数码设备创意",
                        unit_name:"单元1",
                        status:"优选",
                        premium:"169",
                        show_num:"10332226",
                        count:"8",
                        click_num:"1033222",
                        click_rate:"11",
                        tran_num:"1033222",
                        tran_rate:"10"

                    },
                    {
                        type:"类目相关人群",
                        project_name:"蓝牙设备创意",
                        unit_name:"单元2",
                        status:"轮播",
                        premium:"173",
                        show_num:"12398671",
                        count:"6",
                        click_num:"1501479",
                        click_rate:"14",
                        tran_num:"1501479",
                        tran_rate:"12.11"

                    }
                ],
                //状态
                isStatus: false,
                //分页
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                peopleCategory: [
                    { key: '核心人群', value: 'kernel' },
                    { key: '认知人群', value: 'perceive' },
                    { key: '意向人群', value: 'intention' },
                    { key: '竞品人群', value: 'products' },
                    { key: '店铺人群', value: 'store' },
                    { key: '类目相关人群', value: 'category' },
                ],
                exposureIndex: 1,
                allKeywords: null,
            }
        },
        mounted() {
        //   this.getCrowdList();
        //   this.getPlanNameList();
        //   this.setPeopleOrientList();
        //   this.getKeywords()
        },
        methods: {
            ...mapActions([
                'setPeopleOrientList'
            ]),
            getKeywords() {
                keywordOfferRank().then(res => {
                    this.allKeywords = res.data
                }).catch(err => {
                    console.log(err)
                })
            },
            //获取计划名称列表
            getPlanNameList() {
              this.$http.axiosGet(this.$api.SDPlanList, (res) => {
                  if (res.code === 200) {
                      this.planOptions = res.data;
                  } else {
                      this.$message.warning(res.msg)
                  }
              }, (err) => {
                  console.log(err);
              })
            },
            //选择计划名称
            selectPlan(val) {
                // console.log('11',val)
                this.planValue = val;
                this.getUnitNameList();
                this.getCrowdList();
            },
            //选择单元名称
            selectUnit(val) {
                // console.log('22',val)
                this.unitValue = val;
                this.getCrowdList();
            },
            //获取单元名称列表
            getUnitNameList() {
                this.$http.axiosGetBy(this.$api.SDunList, {project_name: this.planValue}, (res) => {
                    if (res.code === 200) {
                        this.unitOptions = res.data;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //获取人群定向列表
            getCrowdList() {
                let param = {
                    type: 'page',
                    limit: this.listPages.eachPageNum,
                    page: this.listPages.currentPageNum
                }
                if (this.planValue) {
                    param.project_name = this.planValue;
                }
                if (this.unitValue) {
                    param.unit_name = this.unitValue;
                }
                this.$http.axiosGetBy(this.$api.SDeppList, param, (res) => {
                    if (res.code === 200) {
                        this.crowdList = _.map(res.data.data, (item) => {
                            if (item.status === 1) {
                                item.status = true;
                            } else {
                                item.status = false;
                            }
                            return item;
                        });
                        this.listPages.total = res.data.total;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //改变状态
            changeStatus(callback, row) {
                if (!row.status) {
                    let param = {
                        id: row.id,
                        data: {
                            status: 0,
                            show_num: 0,
                            click_num: 0,
                            show_rank: 0,
                            tran_num: 0
                        }
                    }
                    this.editCrowdOrientation(param);
                } else {
                    this.validateNum(row);
                }
            },
            editCrowdOrientation(param) {
                this.$http.axiosPost(this.$api.ep_peoplecrowd, param, (res) => {
                    if (res.code === 200) {
                        this.$message({
                            type: 'success',
                            message: res.msg,
                            duration: 1000,
                            onClose: () => {
                                this.getCrowdList();
                                this.setPeopleOrientList();
                            }
                        });
                    } else {
                        this.$message({
                            type: 'warning',
                            message: res.msg,
                            duration: 1000
                        });
                    }
                }, (err) => {
                    this.$common.axiosErrorMsg(err);
                })
            },
            //失焦事件
            validateNum(row) {
                if (!row.status) {
                    this.$message({
                        type: 'warning',
                        message: '请先开启人群的状态',
                        duration: 1000
                    });
                    this.isShow = null;
                    return;
                }
                if (row.premium <= 500 && row.premium >= 30) {
                    this.isShow = null;
                    let result = this.cacluExposure(row);
                    result.premium = row.premium;
                    result.status = 1;
                    let param = {
                        id: row.id,
                        data: result
                    }
                    this.editCrowdOrientation(param);
                } else {
                    this.$message.error('请输入30-500之间的数值');
                    return false
                }
            },
            cacluExposure(row) {
                let obj = _.find(this.peopleCategory, { key: row.type});
                let moduleInfo = this.$lockr.get('moduleTemplate');
                let people_data = moduleInfo.people_data;
                let base_num = people_data[`${obj.value}_potential_client`];
                let tran_rate = people_data[`${obj.value}_tran_rate`];

                // let keywordIds = _.map(keywords, (item) => {
                //     if (item.ep_id === row.ep_id) {
                //         return item.keyword_id;
                //     }
                // });
                //
                // let kyws_list = _.filter(allKeywords, (item) => {
                //     return keywordIds.indexOf(item.keyword_id) !== -1;
                // });
                const kyws_list = this.allKeywords[row.ep_id];
                // console.log('kyws_list', kyws_list);
                // return
                let arr = []
                if (kyws_list.length) {
                    for (let i = 0; i < kyws_list.length; i++) {
                        let keywordItem = kyws_list[i];
                        for (let j = 0; j < keywordItem.store_rank.length; j++) {
                            let offer = Number(keywordItem.store_rank[j]);
                            if (i === 0) {
                                arr.push(offer);
                            } else {
                                arr[j] = this.$common.toFormat2dot(Number(arr[j]) + offer);
                            }
                        }
                    }

                    let nums = kyws_list.length;
                    arr = _.map(arr, (item) => {
                        return Number(this.$common.toFormat2dot(item / nums));
                    });
                    arr.reverse();
                }
                let store_bugget = moduleInfo.store_offer_data.reverse();

                store_bugget = _.map(store_bugget, (item) => {
                    return Number(item);
                });
                store_bugget = _.sortBy(store_bugget, (item) => {
                    return -item;
                });
                let offer_price = this.$common.toFormat2dot(Number(row.pc_price) * row.premium / 100)
                let tmp_position = this.$common.sortByIndex(arr, offer_price);
                /*
                * 首先算出竞争店铺加学生的平均出价排名， 第一名=这个人群的潜在客户数， 第二名=第一名的曝光量预估-（（第二名日限额/出价）*这个人群的点击率）
                  曝光人数预估=曝光量预估*这个人群的转化率
                * */
                let exposureNum = this.exposureTotal(base_num, row.tran_rate, tmp_position, store_bugget, arr, offer_price);
                row.show_num = exposureNum;
                row.show_rank = tmp_position;
                row.tran_num = Math.floor(exposureNum * row.tran_rate / 100);
                row.click_num = Math.floor(row.tran_num * row.click_rate/100);
                return {show_num: row.show_num, tran_num: row.tran_num, click_num: row.click_num, show_rank: row.show_rank};
            },
            exposureTotal(exposure_num, tran_rate, position, store_bugget, arr, offer) {
                position = Number(position);
                if (position === this.exposureIndex) {
                    this.exposureIndex = 1;
                    return exposure_num;
                } else {
                    this.exposureIndex++;
                    let dailybugget = 0;
                    let offer_price = (this.exposureIndex >= arr.length) ? offer : arr[this.exposureIndex];
                    dailybugget = store_bugget[this.exposureIndex - 1];
                    exposure_num = exposure_num - Math.floor((dailybugget / offer_price) * tran_rate / 100);
                    return this.exposureTotal(exposure_num, tran_rate, position, store_bugget, arr, offer);
                }
            },
            //修改出价
            clickPrice(row) {
                this.isShow = row.id;
                //点开输入框  聚焦
                setTimeout(()=> {
                    this.$refs[`premiumInput_${row.id}`].focus();
                }, 100);
            },
            //每页显示几条
            handleSizeChange(val) {
                this.listPages.eachPageNum = val;
                this.getCrowdList();
            },
            //分页
            handleCurrentChange(val) {
                this.listPages.currentPageNum = val;
                this.getCrowdList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .el-switch.is-checked .el-switch__core {
        border-color: #20D08C;
        background-color: #20D08C;
    }
    .promotion-content {
        margin-right: 20px;
        min-height: calc(100vh - 299px);
        .table-btn {
            padding-top: 10px;
        }
        /deep/ .el-table {
            min-height: calc(100vh - 447px);
            .el-table__body {
                tr td {
                    padding: 27px 0;
                }
                .cell {
                    .plan-name {
                        display: flex;
                        align-items: center;
                        /*justify-content: center;*/
                        cursor: pointer;
                        color: #1E63F1;
                        .edit-plan-iocn {
                            display: none;
                            font-size: 14px;
                            margin-left: 10px;
                        }
                        &:hover {
                            color: #1E63F1;
                            .edit-plan-iocn {
                                display: block;
                                color: #333;
                            }
                        }
                    }
                    .name-input {
                        display: block;
                        position: absolute;
                        top: 26px;
                        height: 18px;
                        width: 71px;
                    }
                    .daily-budget {
                        cursor: pointer;
                        color: #1E63F1;
                    }
                    .del-blue-btn {
                        cursor: pointer;
                        color: #1E63F1;
                    }
                }
            }
        }
    }
    .keyword-select {
        padding-top: 10px;
    }
</style>